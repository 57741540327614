class GeoPlace {
  constructor(data) {
    this.data = data;
  }

  get name() {
    if (this.data.name) {
      return `${this.data.name}, ${this.data.countryCode}`
    } else {
      return this.data.country
    }
  }

  get altitude() {
    return [
      this.data.astergdem,
      this.data.srtm3,
      this.data.gtopo30,
      this.data.srtm1,
      this.data.elevation,
    ].find(el => el !== undefined);
  }
}

export async function reverseGeoLookup(lat, long) {
  const username = process.env.VUE_APP_GEONAMES_USERNAME

  let response = await fetch(`https://secure.geonames.org/findNearbyPlaceNameJSON?lat=${lat}&lng=${long}&username=${username}&style=full`)
  let responseJson = await response.json()

  if (responseJson.geonames.length == 0) {
    return null;
  }

  return new GeoPlace(responseJson.geonames[0]);
}