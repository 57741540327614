import { strToU8, compressSync, decompressSync, strFromU8 } from 'fflate'
import { Base64 } from 'js-base64'

const LOCATIONS = {
  Eugene: { lat: 44.04990, long: -123.0868, alt: 131, display: "Eugene, USA" },
  Boise: { lat: 43.618881, long: -116.215019, alt: 832.1, display: "Boise, USA" },
  Chicago: { lat: 41.510395, long: -87.644287, alt: 182, display: "Chicago, USA" },
  Barcelona: { lat: 41.390205, long: 2.154007, alt: 12, display: "Barcelona, ESP" },
  Noida: { lat: 28.535517, long: 77.391029, alt: 200, display: "Noida, IND" },
  Edinburgh: { lat: 55.953251, long: -3.188267, alt: 47, display: "Edinburgh, UK" },
  Tokyo: { lat: 35.652832, long: 139.839478, alt: 40, display: "Tokyo, JPY" },
}

const defaultConfig = {
  locations: [LOCATIONS.Eugene, LOCATIONS.Boise, LOCATIONS.Edinburgh, LOCATIONS.Noida],
  render: {
    type: "simple",
    bubbleRadius: 20,
    bubbleSpacing: 20
  },
  // render: {
  //   type: "voronoi",
  //   batchRender: true,
  //   renderOptimize: false,
  //   cellGeneration: {
  //     gridSize: 50,
  //     density: 0.0025
  //   },
  // }
}

export function encodeConfigHash(config) {
  const buf = strToU8(JSON.stringify(config))
  let compressed = compressSync(buf, { level: 9, mem: 8 });
  return Base64.fromUint8Array(compressed, true)
}

export function parseConfigHash(hash) {
  const decompressed = decompressSync(Base64.toUint8Array(hash));
  return JSON.parse(strFromU8(decompressed))
}

export function getConfigFromURL() {
  try {
    return parseConfigHash(window.location.hash.substring(1))
  } catch (e) {
    console.log('Unable to get config from URL due to error:', e)
    console.log('Using default config')
    return defaultConfig
  }
}

export function setConfigURL(config) {
  window.location.hash = encodeConfigHash(config)
}