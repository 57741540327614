import { SimpleRenderer } from './render/simple'
import { VoronoiRenderer } from './render/voronoi'
import { SkyManager } from './skymanager'

// For animation
const ANIM_ENABLED = false
const ANIM_TIME_START = new Date("2022.04.29")
const ANIM_TIME_END = new Date("2022.04.30")
const ANIM_LENGTH = 60 // seconds


const RENDERERS = {
  simple: SimpleRenderer,
  voronoi: VoronoiRenderer,
}

export class WorldClockSketch {
  constructor(p5, config) {
    this.p5 = p5
    this.config = config
    this.display_labels = false
    this.display_debug = false

    // Defined in setup
    this.skyManager = null
    this.renderer = null
  }

  setup() {
    this.p5.createCanvas(this.p5.windowWidth, this.p5.windowHeight);
    this.p5.frameRate(30)

    this.skyManager = new SkyManager(this.p5, this.config.locations)
    this.renderer = new RENDERERS[this.config.render.type](this.p5, this.config, this.skyManager)

    this.skyManager.updateLoop()
  }

  remove() {
    this.p5.remove()
  }

  keyPressed() {
    if (this.p5.key == " ") {
      this.display_labels = !this.display_labels
    } else if (this.p5.key == "d") {
      this.display_debug = !this.display_debug
    } else if (this.p5.key == "f") {
      let fs = this.p5.fullscreen();
      this.p5.fullscreen(!fs);
    }
  }

  windowResized() {
    this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
    this.renderer.windowResized()
  }

  draw() {
    let drawStartTime = this.p5.millis()

    // colorMode(REPLACE)

    let datetime = new Date()

    if (ANIM_ENABLED) {
      let anim_pos = (this.p5.millis() % (ANIM_LENGTH * 1000)) / (ANIM_LENGTH * 1000)
      datetime = new Date(this.p5.lerp(ANIM_TIME_START.getTime(), ANIM_TIME_END.getTime(), anim_pos))
    }

    this.skyManager.update(datetime)

    let clockUpdateTime = this.p5.millis()

    this.renderer.draw()
    // cellRender()

    if (this.display_labels) this.labelRender()

    let renderTime = this.p5.millis()

    if (this.display_debug) this.debugRender(clockUpdateTime - drawStartTime, renderTime - clockUpdateTime)
  }

  labelRender() {
    this.p5.fill(0)
    this.p5.rect(0, this.p5.height - 20, this.p5.width, 20)
    this.p5.fill(255)

    let locationWidth = this.p5.width / this.config.locations.length

    for (let locationIdx = 0; locationIdx < this.config.locations.length; locationIdx++) {
      this.p5.text(this.config.locations[locationIdx].display, locationIdx * locationWidth, this.p5.height - 8)
    }
  }

  debugRender(clockUpdateMillis, renderMillis) {
    this.p5.fill(0)
    this.p5.rect(0, 0, 100, 60)
    this.p5.fill(255)
    let clockUpdatePercent = this.p5.round(clockUpdateMillis / (clockUpdateMillis + renderMillis) * 10) * 10
    let renderPercent = this.p5.round(renderMillis / (clockUpdateMillis + renderMillis) * 10) * 10
    this.p5.text(`FPS: ${this.p5.round(this.p5.frameRate())}`, 0, 10)
    this.p5.text(`CUpdate:`, 0, 20)
    this.p5.text(`${this.p5.round(clockUpdateMillis, 1)}`, 60, 20)
    this.p5.text(`Render:`, 0, 30)
    this.p5.text(`${this.p5.round(renderMillis, 1)}`, 60, 30)
    this.p5.text(`C% / R%:  ${this.p5.round(clockUpdatePercent)} / ${this.p5.round(renderPercent)}`, 0, 40)
    // text(`Cells: ${cells.length}`, 0, 50)
  }
}