<template>
  <n-config-provider>
    <n-message-provider>
      <ClockGUI/>
    </n-message-provider>
  </n-config-provider>
</template>

<script>
import "leaflet/dist/leaflet.css"
import ClockGUI from './components/ClockGUI.vue'

export default {
  name: 'App',
  components: {
    ClockGUI
  }
}
</script>

<style>
</style>
