import p5 from 'p5'

import { WorldClockSketch } from "./sketch"

let worldClockSketch

export function runClock(config) {
  if (worldClockSketch) {
    worldClockSketch.remove()
  }

  new p5((sketch) => {
    worldClockSketch = new WorldClockSketch(sketch, config)

    sketch.setup = worldClockSketch.setup.bind(worldClockSketch)
    sketch.keyPressed = worldClockSketch.keyPressed.bind(worldClockSketch)
    sketch.windowResized = worldClockSketch.windowResized.bind(worldClockSketch)
    sketch.draw = worldClockSketch.draw.bind(worldClockSketch)
  }, "sketch")
}