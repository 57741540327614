import p5 from 'p5'

import Voronoi from "voronoi"

// let config = {
//   batchRender = true/false,
//   renderOptimize = true/false
// }

export class VoronoiRenderer {
  constructor(p5, config, skyManager) {
    this.p5 = p5
    this.config = config
    this.skyManager = skyManager

    this.diagram = null
    this.voronoi = new Voronoi()
    this.cells = []
    this.cellFrameCount = 0

    this.setup()
  }

  windowResized() {
    this.setup()
  }

  setup() {
    this.cellFrameCount = 0

    if (this.diagram != null)
      this.voronoi.recycle(this.diagram)

    let gridSize = this.config.render.cellGeneration.gridSize
    let density = this.config.render.cellGeneration.density

    let siteCount = gridSize * gridSize * density

    let sites = this.generateSiteLocations(this.p5.width, this.p5.height, gridSize, siteCount)
    this.diagram = this.voronoi.compute(sites, { xl: 0, xr: this.p5.width, yt: 0, yb: this.p5.height })

    this.cells = []
    for (let cell of this.diagram.cells) {
      if (cell.halfedges.length == 0)
        continue;

      this.cells.push(new Cell(this.p5, cell))
    }

    this.p5.shuffle(this.cells, true)
  }

  generateSiteLocations(w, h, groupingSize, sitesPerGroup) {
    let sites = []
    for (let y = -0.5 * groupingSize; y < h; y += groupingSize) {
      for (let x = -0.5 * groupingSize; x < w; x += groupingSize) {
        for (let i = 0; i < sitesPerGroup; i++) {
          let sx = this.p5.random(x, x + groupingSize)
          let sy = this.p5.random(y, y + groupingSize)
          sites.push(new p5.Vector(sx, sy))
        }
      }
    }

    return sites
  }

  draw() {
    if (!this.config.render.batchRender)
      this.p5.background(0)

    for (let cell of this.cells) {
      cell.update(this.p5.deltaTime)
    }

    let cellBatchCount = 5000
    let batchIdx = this.cellFrameCount % cellBatchCount
    let batchSize = this.p5.floor(this.cells.length / cellBatchCount)
    let batchStart = this.p5.floor(batchIdx * batchSize)
    let batchEnd = this.p5.min(this.cells.length, batchStart + batchSize)

    if (this.cellFrameCount <= 10 || !this.config.render.batchRender) {
      batchStart = 0
      batchEnd = this.cells.length
    }

    for (let cIdx = batchStart; cIdx < batchEnd; cIdx++) {
      let cell = this.cells[cIdx]
      let [r, g, b] = this.skyManager.coordToColor(cell.center.x, cell.center.y)
      if (r != null) {
        cell.update(this.p5.deltaTime)

        this.p5.noStroke()
        this.p5.fill(r, g, b, 100)

        if (r + g + b != 0 || !this.config.render.renderOptimize) // Only draw when it will change something, does cause a slight difference in rendering
          cell.drawShape(this.p5)
      }
    }

    this.cellFrameCount++
  }
}

class Cell {
  constructor(p5inst, voronoiCell) {
    this.rotation = 0
    this.rotationSpeed = p5inst.random(-1, 1)
    this.points = []
    this.scale = 3

    let site = voronoiCell.site
    this.center = new p5.Vector(site.x, site.y)

    for (let halfedge of voronoiCell.halfedges) {
      let e = halfedge.getEndpoint()
      this.points.push(new p5.Vector(e.x - this.center.x, e.y - this.center.y))
    }
  }

  update(dt) {
    this.rotation += dt * this.rotationSpeed * 0.00001
  }

  drawShape(p5inst) {
    p5inst.beginShape()
    for (let pt of this.points) {
      let rotated = p5.Vector.rotate(pt, this.rotation).mult(this.scale).add(this.center)
      p5inst.vertex(rotated.x, rotated.y)
    }
    p5inst.endShape(p5inst.CLOSE)
  }
}