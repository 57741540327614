<template>
  <!-- <n-space> -->
    <n-button circle size='small' color="#0077B5" @click="openlinkedinShare">
      <template #icon>
        <n-icon>
          <BrandLinkedin />
        </n-icon>
      </template>
    </n-button>
    <n-button circle size='small' color="#FF5700" @click="openRedditShare">
      <template #icon>
        <n-icon>
          <BrandReddit />
        </n-icon>
      </template>
    </n-button>
    <n-button circle size='small' color="#4267B2" @click="openFacebookShare">
      <template #icon>
        <n-icon>
          <BrandFacebook />
        </n-icon>
      </template>
    </n-button>
    <n-button circle size='small' color="#1DA1F2" @click="openTwitterShare">
      <template #icon>
        <n-icon>
          <BrandTwitter />
        </n-icon>
      </template>
    </n-button>
    <n-button type="default" circle size='small' @click="copyToClipboard">
      <template #icon>
        <n-icon>
          <Link />
        </n-icon>
      </template>
    </n-button>
  <!-- </n-space> -->
</template>

<script>
/*
  Any social sites included in this project should provide ways of sharing without loading external scripts.
  The goal is to prevent these sites from tracking users just by including sharing buttons
*/

import {BrandLinkedin, BrandFacebook, BrandReddit, BrandTwitter, Link} from '@vicons/tabler'
import { useMessage } from 'naive-ui'

// This page has helped quite a bit: https://github.com/bradvin/social-share-urls
export default {
  name: 'SocialShare',
  props: [],
  components: {
    BrandLinkedin,
    BrandFacebook,
    BrandReddit,
    BrandTwitter,
    Link
  },
  data() {
    return {
      message: useMessage()
    }
  },
  methods: {
    openTwitterShare() {
      window.open(this.twitterURL, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    },
    openRedditShare() {
      window.open(this.redditURL, '', '');
    },
    openFacebookShare() {
      window.open(this.facebookURL, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    },
    openlinkedinShare() {
      window.open(this.linkedinURL, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    },
    copyToClipboard() {
      navigator.clipboard.writeText(location.href)
      this.message.info("URL copied to clipboard",
        {
          closable: true,
          duration: 1000,
          showIcon: false
        })
    }
    
  },
  computed: {
    twitterURL() {
      let encodedURL = encodeURIComponent(location.href)
      let hashTags = "sunclock"
      let title = "Check out this world sun clock I made!"
      return `https://twitter.com/intent/tweet?url=${encodedURL}&text=${title}&hashtags=${hashTags}`
    },
    redditURL() {
      let encodedURL = encodeURIComponent(location.href)
      let title = "World Sun Clock"
      return `https://reddit.com/submit?url=${encodedURL}&title=${title}`
    },
    facebookURL() {
      // https://developers.facebook.com/docs/sharing/reference/share-dialog
      let encodedURL = encodeURIComponent(location.href)
      let appId = process.env.VUE_APP_FB_APP_ID
      return `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${encodedURL}&redirect_uri=${encodedURL}`
    },
    linkedinURL() {
      let encodedURL = encodeURIComponent(location.href)
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`
    }
  }
}
</script>

<style>

</style>