import { createApp } from 'vue'
import naive from "naive-ui";
import App from './App.vue'
import { runClock } from '../clock/main.js'
import { getConfigFromURL } from './confighash';

let app = createApp(App);

app.use(naive);

app.mount('#app')
runClock(getConfigFromURL())
