<template>
  <div>
    <l-map style="height:50vh" @click="handleClick" :zoom="2">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>

      <l-marker v-for="(loc, index) in modelValue" :key="index"
        :lat-lng="[loc.lat, loc.long]"
        @click="removeMarker(index)"
        @move="moveMarker(index, $event)"
        draggable>
        <l-tooltip>
          {{loc.display}}
        </l-tooltip>
      </l-marker>
    </l-map>
    <table>
      <tr>
        <th>Display Name</th>
        <th>Altitude</th>
        <th>Latitude</th>
        <th>Longitude</th>
        <th></th>
      </tr>
      <tr v-for="(loc, index) in modelValue" :key="loc.id">
        <td><n-input v-model:value="loc.display" type="text" /></td>
        <td><n-input-number v-model:value="loc.alt" :show-button="false" /></td>
        <td><n-input-number v-model:value="loc.lat" :show-button="false" /></td>
        <td><n-input-number v-model:value="loc.long" :show-button="false" /></td>
        <td><button @click="removeMarker(index)">del</button></td>
      </tr>
    </table>
  </div>
</template>
<script>
// TODO: Make this do deep copies when updating data
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import {reverseGeoLookup} from "../geonames.js"
import {deepClone} from "../util.js"

export default {
  name: "LocationsSelector",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      locations: [],
      lastId: 0,
    }
  },
  watch: {
    modelValue(newVal) {
      this.locations = deepClone(newVal)
      for (let location of this.locations) {
        if (location.id == undefined)
          location.id = this.lastId++
      }
    }
  },
  mounted() {
    this.locations = deepClone(this.modelValue)
    for (let location of this.locations) {
      if (location.id == undefined)
        location.id = this.lastId++
    }
  },
  methods: {
    handleClick(event) {
      if (!event.latlng) return;

      this.locations.push({display: "", alt: 100, lat: event.latlng.lat, long: event.latlng.lng, id: this.lastId++})
      this.lookupAddress(this.locations.length - 1)
      this.$emit("update:modelValue", this.locations)
    },
    async lookupAddress(idx) {
      let location = this.locations[idx];
      
      let geoplace = await reverseGeoLookup(location.lat, location.long) // TODO: relying on idx here could create a race condition if list is changed while waiting
      if (geoplace) { 
        this.locations[idx].display = geoplace.name 
        this.locations[idx].alt = geoplace.altitude
      }

      this.$emit("update:modelValue", this.locations)
    },
    removeMarker(index) {
      this.locations.splice(index, 1)
      this.$emit("update:modelValue", this.locations)
    },
    moveMarker(index, event) {
      let location = this.locations[index]
      location.lat = event.latlng.lat
      location.long = event.latlng.lng
      this.$emit("update:modelValue", this.locations)
    }
  }
}
</script>