<template>
  <!-- <n-form
    ref="formRef"
    inline
    :model="formValue"
  > -->
    <n-form-item label="Renderer">
      <n-select :value="modelValue.type" @update:value="emitUpdate('type', $event)" :options="rendererOptions" />
    </n-form-item>
    <div v-if="modelValue.type != ''">
      <SimpleRendererConfig v-if="modelValue.type == 'simple'" :modelValue="modelValue" @update:modelValue="this.$emit('update:modelValue', $event)"/>
      <VoronoiRendererConfig v-else-if="modelValue.type == 'voronoi'" :modelValue="modelValue" @update:modelValue="this.$emit('update:modelValue', $event)"/>
    </div>
  <!-- </n-form> -->
</template>

<script>
import SimpleRendererConfig from "./SimpleRendererConfig.vue"
import VoronoiRendererConfig from "./VoronoiRendererConfig.vue"
import {deepClone} from "../util.js"
export default {
  name: 'RenderConfig',
  props: ["modelValue"],
  emits: ["update:modelValue"],
  components: {
    SimpleRendererConfig,
    VoronoiRendererConfig
  },
  data() {
    return {
      rendererOptions: [
        {
          label: 'Simple',
          value: 'simple'
        },
        {
          label: 'Voronoi',
          value: 'voronoi'
        },
      ]
    }
  },
  methods: {
    emitUpdate(key, event) {
      let val = deepClone(this.modelValue)
      val[key] = event
      this.$emit("update:modelValue", val)
    },
    rendererName() {
      return this.modelValue.type.charAt(0).toUpperCase() + this.modelValue.type.slice(1);
    }
  }
}
</script>