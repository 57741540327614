<template>
  <n-button strong text circle @click="activateDrawer()" class="drawerbutton" v-if="!buttonHidden">
    <n-icon>
      <ExpandCircleDownTwotone />
    </n-icon>
  </n-button>
  <n-drawer
    v-model:show="drawerActive" 
    default-height="400"
    placement="top" 
    resizable>
    <n-drawer-content footer-style="justify-content: normal">
      <n-tabs default-value="welcome" justify-content="space-evenly" type="line">
        <template #prefix>
          <div class="header">World Sun Clock</div>
          <n-button type="primary" @click="updateClock()" round>
            <template #icon>
              <RotateClockwise />
            </template>
            Update Clock
          </n-button>
        </template>
        <template #suffix>
          <social-share />
        </template>
        <n-tab-pane name="welcome" tab="Welcome">
          WELCOME!
        </n-tab-pane>
        <n-tab-pane name="locations" tab="Locations">
          <LocationsSelector v-model="locations" />
        </n-tab-pane>
        <n-tab-pane name="render" tab="Renderer">
          <RenderConfig v-model="renderConfig" />
        </n-tab-pane>
        <n-tab-pane name="advanced" tab="Advanced">
          <n-statistic label="Encoded config length">
            {{encodedConfigLength}}
          </n-statistic>
          <pre>{{config}}</pre>
        </n-tab-pane>
      </n-tabs>
    </n-drawer-content>
  </n-drawer>
</template>

<script>
import LocationsSelector from './LocationsSelector.vue'
import RenderConfig from './RenderConfig.vue'
import SocialShare from './SocialShare.vue'
import { runClock } from '../../clock/main'
import { encodeConfigHash, getConfigFromURL, setConfigURL } from '../confighash'
import { ExpandCircleDownTwotone } from '@vicons/material'
import {RotateClockwise} from '@vicons/tabler'

const BUTTON_HIDE_TIMEOUT = 5000

export default {
  name: 'ClockGUI',
  components: {
    LocationsSelector,
    RenderConfig,
    ExpandCircleDownTwotone,
    RotateClockwise,
    SocialShare
  },
  data() {
    return {
      drawerActive: false,
      renderConfig: {
        type: ""
      },
      locations: [],
      buttonHidden: false,
      hideButtonTimeout: undefined,
      shareModal: false
    }
  },
  mounted() {
    document.addEventListener("mousemove", this.onmousemove);
    
    let config = getConfigFromURL()
    if (!config) return

    this.locations = config.locations
    this.renderConfig = config.render
  },
  methods: {
    activateDrawer() {
      this.drawerActive = true
      clearTimeout(this.hideButtonTimeout)
    },
    updateClock() {
      setConfigURL(this.config)
      runClock(this.config)
    },
    hideButton() {
      this.buttonHidden = true
    },
    onmousemove() {
      this.buttonHidden = false
      clearTimeout(this.hideButtonTimeout)

      this.hideButtonTimeout = setTimeout(this.hideButton, BUTTON_HIDE_TIMEOUT)
    },
    openShareModal() {
      this.shareModal = true
    },
  },
  computed: {
    config() {
      return {
        locations: this.locations,
        render: this.renderConfig
      }
    },
    encodedConfigLength() {
      return encodeConfigHash(this.config).length
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.drawer {
  min-width: 300px;
  width: 80%;
  margin: auto 10%;
  margin-bottom: 20%;
  overflow-y: scroll;
  /* min-height: 80%; */
  height: 80%;
}

.drawerbutton {
  margin: auto 50%;
  font-size: 24px;
}

.header {
  margin-right: 40px;
  font-size: x-large;
  font-weight: 400;
}
</style>
