import { SkyCalculator } from "./skycalc"

export class SkyManager {
  constructor(p5, locations) {
    this.p5 = p5
    this.locations = locations
    this.skyCalcs = []

    this.initSkys()
    this.datetime = new Date()
    this.resumable = new ResumableContext()
  }

  initSkys() {
    let locationRatio = 1 / this.locations.length

    // Calc xRes based on y res, but make sure we get at least 3 points
    let yRes = 80
    let xRes = this.p5.floor(this.p5.constrain(locationRatio * yRes, 3, yRes))
    for (let location of this.locations) {
      this.skyCalcs.push(new SkyCalculator(this.p5, {
        location: location,
        skyRange: {
          x: [-locationRatio, locationRatio],
          y: [-1, 1]
        },
        resolution: {
          x: xRes,
          y: 40
        }
      }))
    }
  }

  update(datetime) {
    this.datetime = datetime
    this.resumable.resume()
  }

  async updateLoop() {
    // Async loop to control calculation
    while (true) { // eslint-disable-line no-constant-condition
      for (let skyCalc of this.skyCalcs) {
        await skyCalc.calculate(this.datetime, this.resumable)
        await yieldToEventLoop() // Just in case the above resolves immediately
      }
    }
  }

  coordToColor(x, y) {
    x = this.p5.constrain(x, 0, this.p5.width)
    y = this.p5.constrain(y, 0, this.p5.height)

    let locationWidth = this.p5.width / this.locations.length
    let locationIdx = this.p5.floor(x / locationWidth)
    locationIdx = this.p5.constrain(locationIdx, 0, this.locations.length - 1) // Not all sites are within the screen so we have to constrain it

    let skyCalc = this.skyCalcs[locationIdx]

    let sx = (x - (locationIdx * locationWidth)) / (locationWidth / skyCalc.resolution.x)
    let sy = y / (this.p5.height / skyCalc.resolution.y)

    return skyCalc.getColor(sx, sy)
  }
}

async function yieldToEventLoop() {
  return new Promise((resolve) => {
    setImmediate(resolve)
  })
}

class ResumableContext {
  constructor() {
    this.readyPromise()
  }

  readyPromise() {
    this.pendingPromise = new Promise((resolve) => {
      this.pendingResolve = resolve
    })
  }

  resume() {
    // We need to ready the next promise before resolving the current one
    let pending = this.pendingResolve
    this.readyPromise()
    pending()
  }

  async wait() {
    return this.pendingPromise
  }
}